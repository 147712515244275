import config from '../config'
import axios from 'axios'

const mainSiteUrl = config.siteUrl + 'company/dashboard.php';

const isLive = true;

const clientService = {
	get: async (endPoint, cancelReq = {}) => {
		var headers = {
			headers: {
				'auth-token': localStorage.getItem('token'),
			},
			...cancelReq
		}
		try{
			return await axios.get(config.apiUrl + endPoint, headers)
		}catch(e) {
			if(isLive && (e?.response?.status === 401 || e?.response?.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	post: async (endPoint, data, cancelReq = {}) => {
		var headers = {
			headers: {
				'auth-token': localStorage.getItem('token'),
			},
			...cancelReq
		}
		try{
			return await axios.post(config.apiUrl + endPoint, data, headers)
		} catch(e) {
            if(e?.code == 'ECONNABORTED' || e?.code == 'ERR_NETWORK') {
                var errorResponse = {
                    data : {
                        status: 504
                    }
                }
                return errorResponse;
            } else if(isLive && (e?.response?.status === 401 || e?.response?.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	put: async (endPoint, data) => {
		var headers = {
			headers: {
				'auth-token': localStorage.getItem('token'),
			},
		}
		try{
			return await axios.put(config.apiUrl + endPoint, data, headers)
		}catch(e) {
			if(isLive && (e.response.status === 401 || e.response.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	remove: async (endPoint) => {
		var headers = {
			headers: {
				'auth-token': localStorage.getItem('token'),
			},
		}
		try{
			return await axios.delete(config.apiUrl + endPoint, headers)
		}catch(e) {
			if(isLive && (e.response.status === 401 || e.response.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	fileUpload: async (endPoint, data, file) => {
		var formData = new FormData()
		formData.append('csv', file, "inport.csv");
		const key = Object.keys(data)
		for (var i = 0; i < key.length; i++) {
			formData.append(key[i], data[key[i]]);
		}

		const headers = {
			headers: {
				//'content-type': 'multipart/form-data',
                //'Access-Control-Allow-Origin': '*',
				'auth-token': localStorage.getItem('token'),
			},
            timeout:150000
		}
		try{
			return await axios.post(config.apiUrl + endPoint, formData, headers)
		}catch(e) {
            if(e.code == 'ECONNABORTED' || e.code == 'ERR_NETWORK') {
                var errorResponse = {
                    data : {
                        status: 504
                    }
                }
                return errorResponse;
            } else if(isLive && (e?.response?.status === 401 || e?.response?.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	pdfUpload: async (endPoint, data, file) => {
		const formData = new FormData()
		for(var i = 0; i < file.length; i++){
			formData.append('sampleFile_' + i, file[i])
		}
		const key = Object.keys(data)
		for (var i = 0; i < key.length; i++) {
			formData.append(key[i], data[key[i]])
		}
		const headers = {
			headers: {
				//'content-type': 'multipart/form-data',
				'auth-token': localStorage.getItem('token'),
			},
		}
		try{
			return await axios.post(config.apiUrl + endPoint, formData, headers)
		}catch(e) {
			if(isLive && (e.response.status === 401 || e.response.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	filesUpload: async (endPoint, data, files) => {
		var formData = new FormData()
		formData.append('postdata', JSON.stringify(data));
		for (let i = 0 ; i < files.length ; i++) {
			formData.append("filedata", files[i]);
		}
		const headers = {
			headers: {
				//'content-type': 'multipart/form-data',
				'auth-token': localStorage.getItem('token'),
			},
		}

		try {
			return await axios.post(config.apiUrl + endPoint, formData, headers)
		} catch(e) {
			if(isLive && (e.response.status === 401 || e.response.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
	},
	head: async(data) => {
    try {
			return await axios.head(data)
		} catch(e) {
			if(isLive && (e.response?.status === 401 || e.response?.status === 500) ){
				window.location.href = mainSiteUrl;
			}
		}
  }
}

export default clientService
